<template>
  <operator-container>
    <template #header></template>
    <template #content>
      <div class="container">
        <div
          v-for="channel in tableData"
          :key="channel.title"
          class="channel-item"
        >
          <div class="channel-title">
            <span>{{ channel.title }}</span>
          </div>
          <div class="channel-video">
            <div
              v-for="videoItem in channel.videoList"
              :key="videoItem.id"
              class="video-item"
            >
              <div class="video-item-content">
                <div class="list-item-image" @click="clickImage(videoItem)">
                  <img :src="videoItem.img" class="image" />
                  <span class="length">{{ videoItem.videoLength }}</span>
                  <div class="mask list-item-play-mask">
                    <i class="el-icon-video-play play-icon"></i>
                  </div>
                </div>
                <div class="video-title">
                  <p>{{ videoItem.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="document">
          <el-button type="text" @click="jumpToDocument"
            ><h2>查看详细教程文档</h2></el-button
          >
        </div>
      </div>
    </template>
    <video-player-dialog
      :dialog-visible.sync="VideoPlayerDialogVisible"
      :video-path="videoPath"
    >
    </video-player-dialog>
  </operator-container>
</template>

<script>
import OperatorContainer from "components/container/operator-container";
import VideoPlayerDialog from "pages/product/views/video-bank/dialog/videoPlayerDialog";
import { userCourseUrl } from "@/common/js/config";
import { PRODUCT_RELEASE_DETAIL_VIDEO_FAST_VERSION } from "@/common/const/video-course";

export default {
  name: "VideoCourse",
  components: {
    VideoPlayerDialog,
    OperatorContainer,
  },
  mixins: [],
  props: {},
  data() {
    return {
      tableData: [
        {
          title: "登录软件及初次使用",
          description: "",
          videoList: [
            {
              id: 0,
              title: "插件安装教程",
              img: "https://oss.maoniux.com/__assets/superman/guide/image/%E6%8F%92%E4%BB%B6%E5%AE%89%E8%A3%85%E6%95%99%E7%A8%8B%E5%B0%81%E9%9D%A2.jpg",
              videoSrc:
                "https://oss.maoniux.com/__assets/superman/video/%E6%8F%92%E4%BB%B6%E5%AE%89%E8%A3%85%E6%95%99%E7%A8%8B%20%E9%99%8D%E5%99%AA%E7%89%88.mp4",
              description: "包含采集品发布演示视频",
              videoLength: "2:31",
            },
          ],
        },
        {
          title: "产品发布相关",
          description: "",
          videoList: [
            {
              id: 0,
              title: "采集品发布演示视频",
              img: "https://oss.maoniux.com/__assets/superman/guide/image/%E9%87%87%E9%9B%86%E5%93%81%E5%8F%91%E5%B8%83%E6%BC%94%E7%A4%BA%E5%B0%81%E9%9D%A2.jpg",
              videoSrc:
                "https://assets.maoniux.com/videos/ai-product-helper.mp4",
              description: "包含采集品发布演示视频",
              videoLength: "3:04",
            },
            {
              id: 1,
              title: "产品发布讲解",
              img: "https://oss.maoniux.com/__assets/superman/guide/image/%E4%BA%A7%E5%93%81%E5%8F%91%E5%B8%83%E8%AE%B2%E8%A7%A3.jpg",
              videoSrc: PRODUCT_RELEASE_DETAIL_VIDEO_FAST_VERSION,
              description: "包含采集品发布演示视频",
              videoLength: "19:21",
            },
          ],
        },
        {
          title: "更多教程",
          description: "",
          videoList: [],
        },
      ],
      VideoPlayerDialogVisible: false,
      videoPath: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    mousemove() {
      const play = document.getElementsByClassName("play");
      const mask = document.getElementsByClassName("mask");
      const video = document.getElementsByClassName("video");
      play[0].style.display = "none";
      mask[0].style.display = "none";
      video[0].style.display = "block";
    },
    mouseleave() {
      const play = document.getElementsByClassName("play");
      const mask = document.getElementsByClassName("mask");
      const video = document.getElementsByClassName("video");
      play[0].style.display = "block";
      mask[0].style.display = "block";
      video[0].style.display = "none";
    },
    clickImage(videoItem) {
      this.videoPath = videoItem.videoSrc;
      this.VideoPlayerDialogVisible = true;
    },
    jumpToDocument() {
      return window.open(userCourseUrl, "blank");
    },
  },
};
</script>

<style lang="stylus" scoped>

.container {
  padding: 1rem;
  .channel-item{
    margin-bottom: 2rem;
    padding-bottom: 4px;
    border-bottom: 1px solid #e5e9ef;
    .channel-title{
      height: 24px;
      line-height: 24px;
      font-size 24px;
    }
  }

  .channel-video{
    display flex
    justify-content flex-start
    align-items center
    flex-wrap wrap
    width 80%
    .video-item {
      display flex
      flex-direction column
      justify-content flex-start
      align-items center
      width 280px
      height 190pxpx
      margin-right 20px
      padding-top 10px
      .video-item-content {
        position relative
        width 100%
        height 100%
        padding 0
        margin 0
        .list-item-image{
          display flex;
          align-items  center;
          position relative;
          background-color #ffffff
          height 157.5px
          .image{
            width 100%
            height 100%
            display block;
            vertical-align middle;
            border-radius 6px
          }
          .length{
            background: #111;
            background: rgba(0,0,0,.5);
            border-radius: 5px 0 0 0;
            color: #fff;
            line-height: 20px;
            transition: top .2s ease;
            padding: 0 6px;
            position: absolute;
            right: 0;
            bottom: 0;
          }
          .list-item-play-mask{
            position absolute;
            top: 0;
            background-color: rgba(0,0,0,.2);
            color: #fff;
            height: 100%;
            width: 100%;
            display none;
            font-size 30px;
            .play-icon{
            }
          }
        }
        .list-item-image:hover .list-item-play-mask{
          display flex;
          align-items center;
          justify-content center;
        }

        .video-title{
          margin-top 6px
        }
      }
    }
  }


  .document{
    margin-top 2rem;
  }

}
</style>
